import { readKey, createMessage, encrypt } from "openpgp";

export default async function encryptLeak(pubkey, data) {
  const publicKey = await readKey({ armoredKey: pubkey });

  const options = {
    message: await createMessage({ binary: data }),
    encryptionKeys: publicKey,
    format: "binary",
  };

  return await encrypt(options);
}
