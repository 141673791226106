import JSZip from "jszip";

import encryptLeak from "./encryption.js";
import { upload, setProgressCallback } from "./upload.js";

const progress = document.getElementById("progress");
const uploadSuccess = document.getElementById("upload-success");
const upgradeNotice = document.getElementById("upgrade-notice");
const uploadError = document.getElementById("upload-error");
const questionError = document.getElementById("question-error");
const maxPayload = document.getElementById("maxpayload").value;
const pubkey = document.getElementById("pubkey").value;
const expectedAnswer = document.getElementById("expectedanswer").value;
const fileList = document.getElementById("file-list");
const fileInputLabel = document.getElementsByClassName("fileInputLabel")[0];

let fileCount = 0;

function main() {
  const form = document.querySelector("form");

  if (!window.FileReader || !window.WebSocket) {
    upgradeNotice.className = "visible";

    const elementsToDisable = form.querySelectorAll("input, textarea, button");
    for (const element of elementsToDisable) {
      element.disabled = true;
    }

    return;
  }

  uploadError.addEventListener("click", handleUploadErrorClick);

  form.addEventListener("submit", handleFormSubmit, false);

  const fileInput = document.getElementById("file");
  fileInput.addEventListener("change", handleFilesChange);
}

function addFileToZip(file, zip) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const originalFileName = file.name;
      let fileName = originalFileName;
      // file already exists, don't overwrite
      let prefix = 1;
      while (zip.files[fileName]) {
        fileName = `${prefix}_${originalFileName}`;
        prefix++;
      }
      resolve(zip.file(fileName, reader.result, { binary: true }));
      resolve();
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
}

function handleFilesChange() {
  if (!this.files.length) return false;

  if (this.files[0].size > parseInt(maxPayload)) {
    // eslint-disable-next-line no-alert
    alert("Filen du försöker att ladda upp är för stor. Den får vara max 100 megabyte.");
    this.value = "";
    return;
  }

  let fileName = "";
  if (this.value) {
    fileName = this.value.split("\\").pop();
  }

  if (++fileCount === 1) {
    fileInputLabel.firstChild.textContent = "Lägg till ytterligare fil";
  }

  const listElement = document.createElement("li");

  const replacementInput = this.cloneNode();
  replacementInput.value = "";
  replacementInput.addEventListener("change", handleFilesChange);

  this.parentElement.replaceChild(replacementInput, this);

  const checkmark = document.createElement("img");
  checkmark.src = "img/attachment.svg";
  listElement.appendChild(checkmark);

  const textElement = document.createElement("span");
  textElement.textContent = fileName;
  listElement.appendChild(textElement);

  const newId = `file${fileCount}`;
  this.id = newId;
  this.name = newId;
  this.removeEventListener("change", handleFilesChange);
  listElement.appendChild(this);

  const deleteButton = document.createElement("img");
  deleteButton.src = "img/delete.svg";
  deleteButton.className = "deleteButton";
  deleteButton.addEventListener("click", deleteFile);
  listElement.appendChild(deleteButton);

  fileList.appendChild(listElement);
}

function handleUploadErrorClick() {
  uploadError.className = ".notice";
}

function handleFormSubmit(event) {
  event.preventDefault();

  const description = this.querySelector("#description").value;
  const name = this.querySelector("#name").value;
  const phone = this.querySelector("#phone").value;
  const email = this.querySelector("#email").value;
  let answer = "noCaptcha";
  if (this.querySelector("#answer") !== null) {
    answer = this.querySelector("#answer").value;
  }

  const fileInputs = this.querySelectorAll("input[name^=file]");

  const files = [];
  for (let i = 0; i < fileInputs.length; i++) {
    for (let j = 0; j < fileInputs[i].files.length; j++) {
      files.push(fileInputs[i].files[j]);
    }
  }

  if (files.length === 0 && description.length === 0) {
    // eslint-disable-next-line no-alert
    alert("Ange antingen en beskrivning, eller bifoga minst en fil");
    return;
  }

  const envelop = {
    encryptedOnClient: true,
    description,
    name,
    phone,
    email,
    filenames: files.map((f) => f.name),
  };

  progress.style.display = "block";

  setProgressCallback(handleUploadProgress);
  uploadFiles(envelop, answer, files).then(() => {
    setProgressCallback(null);
  }).catch((err) => {
    progress.removeAttribute("style");
    if (err.message === "failquestion") {
      questionError.querySelector(".qerror").textContent = "Du har angett fel text.";
    } else {
      uploadError.className = "notice visible";
    }
  });
}

async function uploadFiles(envelop, answer, files) {
  const zip = new JSZip();

  zip.file("index.json", JSON.stringify(envelop));

  await Promise.all(files.map((file) => addFileToZip(file, zip)));

  const out = await zip.generateAsync({ type: "uint8array" });
  const outStream = new ReadableStream({
    start(controller) {
      controller.enqueue(out);
      controller.close();
    },
  });
  const encryptedStream = await encryptLeak(pubkey, outStream);
  await upload(encryptedStream, answer, expectedAnswer, out.length, parseInt(maxPayload));
  uploadSuccess.className = "notice visible";
  document.body.className = "locked";

}

function handleUploadProgress(current, total) {
  const progressCount = parseInt(((current / total) * 100), 10);
  progress.querySelector(".count").textContent = `${progressCount}%`;
  if (current >= total) {
    progress.removeAttribute("style");
  }
}

function deleteFile(e) {
  const listElementToDelete = e.target.parentNode;

  listElementToDelete.remove();
  if (!fileList.children.length) {
    --fileCount;
    fileInputLabel.firstChild.textContent = "Välj fil";
  }
}

main();
