let progressCallback;

export function upload(encryptedStream, answer, expectedAnswer, total, chunkSize) {
  return new Promise((resolve, reject) => {
    const protocol = location.protocol === "http:" ? "ws:" : "wss:";
    const socket = new WebSocket(`${protocol}//${location.host}/`);
    const reader = encryptedStream.getReader();
    let processed = 0;
    function uploadLoop(event) {
      if (event.data !== "ok") {
        socket.close();
        if (event.data === "failquestion") return reject(new Error("failquestion"));
        return reject(new Error(`Unexpected data received: ${String(event.data)}`));
      }

      reader.read().then(({ value, done }) => {
        if (done) {
          socket.close();
          return resolve();
        }
        for (let i = 0; i < value.length; i += chunkSize) {
          const chunk = value.subarray(i, i + chunkSize);
          socket.send(chunk);
          processed += chunk.length;
          if (progressCallback) {
            progressCallback(processed, total);
          }
        }
      });
    }

    function onServerReady() {
      socket.onmessage = uploadLoop;
      if (answer === "noCaptcha") {
        socket.send("");
      } else {
        socket.send(JSON.stringify({ answer, expectedAnswer }));
      }
    }

    socket.onmessage = onServerReady;
    socket.onclose = function () {
      reject(new Error("Unexpected close"));
    };
    socket.onerror = function (event) {
      return reject(event);
    };
  });
}

export function setProgressCallback(callback) {
  progressCallback = callback;
}
